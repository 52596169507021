// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-routes-region-layout-js": () => import("./../src/routes/region/layout.js" /* webpackChunkName: "component---src-routes-region-layout-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-en-js": () => import("./../src/pages/about-us.en.js" /* webpackChunkName: "component---src-pages-about-us-en-js" */),
  "component---src-pages-about-us-fr-js": () => import("./../src/pages/about-us.fr.js" /* webpackChunkName: "component---src-pages-about-us-fr-js" */),
  "component---src-pages-about-us-nl-js": () => import("./../src/pages/about-us.nl.js" /* webpackChunkName: "component---src-pages-about-us-nl-js" */),
  "component---src-pages-faq-en-js": () => import("./../src/pages/faq.en.js" /* webpackChunkName: "component---src-pages-faq-en-js" */),
  "component---src-pages-faq-fr-js": () => import("./../src/pages/faq.fr.js" /* webpackChunkName: "component---src-pages-faq-fr-js" */),
  "component---src-pages-faq-nl-js": () => import("./../src/pages/faq.nl.js" /* webpackChunkName: "component---src-pages-faq-nl-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-fr-js": () => import("./../src/pages/index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-nl-js": () => import("./../src/pages/index.nl.js" /* webpackChunkName: "component---src-pages-index-nl-js" */),
  "component---src-pages-list-en-js": () => import("./../src/pages/list.en.js" /* webpackChunkName: "component---src-pages-list-en-js" */),
  "component---src-pages-list-fr-js": () => import("./../src/pages/list.fr.js" /* webpackChunkName: "component---src-pages-list-fr-js" */),
  "component---src-pages-list-nl-js": () => import("./../src/pages/list.nl.js" /* webpackChunkName: "component---src-pages-list-nl-js" */),
  "component---src-pages-pack-restart-en-js": () => import("./../src/pages/pack-restart.en.js" /* webpackChunkName: "component---src-pages-pack-restart-en-js" */),
  "component---src-pages-pack-restart-fr-js": () => import("./../src/pages/pack-restart.fr.js" /* webpackChunkName: "component---src-pages-pack-restart-fr-js" */),
  "component---src-pages-pack-restart-nl-js": () => import("./../src/pages/pack-restart.nl.js" /* webpackChunkName: "component---src-pages-pack-restart-nl-js" */),
  "component---src-pages-payconiq-en-js": () => import("./../src/pages/payconiq.en.js" /* webpackChunkName: "component---src-pages-payconiq-en-js" */),
  "component---src-pages-payconiq-fr-js": () => import("./../src/pages/payconiq.fr.js" /* webpackChunkName: "component---src-pages-payconiq-fr-js" */),
  "component---src-pages-payconiq-nl-js": () => import("./../src/pages/payconiq.nl.js" /* webpackChunkName: "component---src-pages-payconiq-nl-js" */),
  "component---src-pages-qr-code-menu-en-js": () => import("./../src/pages/qr-code-menu.en.js" /* webpackChunkName: "component---src-pages-qr-code-menu-en-js" */),
  "component---src-pages-qr-code-menu-fr-js": () => import("./../src/pages/qr-code-menu.fr.js" /* webpackChunkName: "component---src-pages-qr-code-menu-fr-js" */),
  "component---src-pages-qr-code-menu-nl-js": () => import("./../src/pages/qr-code-menu.nl.js" /* webpackChunkName: "component---src-pages-qr-code-menu-nl-js" */)
}

